import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles'


import CssBaseline from '@material-ui/core/CssBaseline';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import MenuContainer from './menuContainer/MenuContainer';
import CityContainer from './city/cityContainer/CityContainer';


const styles = theme => ({
	root: {
    	display: 'flex',
   		flexWrap: 'wrap',
   		justifyContent: 'space-around',
   		overflow: 'hidden',
   		backgroundColor: theme.palette.background.paper,
  	},
  	content: {
    	padding: 0,
    	width: '100%',
    	height: '100vh'
  	},
    toolbar: {
      padding: theme.spacing(1)
    },
  	appBarDialog: {
    	position: 'relative',
  	},
  	titleDialog: {
   	  marginLeft: theme.spacing(2) ,
    	flex: 1,
  	},
});

const PageDrawer = (props) => {
	const {classes,openDrawer,toggleDrawer,drawer,
		openDialog,handleCloseDialog,cityId} = props

	return <div className={classes.root}>
		<CssBaseline />
		<MenuContainer 
			openDrawer={openDrawer}
			toggleDrawer={toggleDrawer} />
		
    <SwipeableDrawer               
		  open={openDrawer}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)} >
        <div className={classes.toolbar}>
  				<IconButton onClick={toggleDrawer(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
         	{drawer}
        <Divider />
      </SwipeableDrawer>
		<main className={classnames(classes.content)}>
			{props.main}			
		</main>
		<CityContainer openDialog={openDialog}
		  handleCloseDialog={handleCloseDialog}
      cityId={cityId} />    	
	</div>    
}

export default withStyles(styles)(PageDrawer)