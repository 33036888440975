import React, {Component} from 'react';

import { connect } from 'react-redux'
import update from 'immutability-helper';

import MapContainer from '../map/mapContainer/MapContainer';
import CityListContainer from '../city/cityListContainer/CityListContainer';

import { cityActions } from '../../actions'

import PageDrawer from '../PageDrawer';

class MainPage extends Component {
	constructor(props) {
		super(props)

	   this.state = {
            openDrawer: false,
            openDialog: false,
            cityId: 0,
            cityList: [],
        }
    }


    componentWillMount(){
        this.componentWillReceiveProps( this.props );  
    }
    
    componentWillReceiveProps( nextProps ){
        const {dispatch} = this.props   

        if( (nextProps.city 
                && !nextProps.city.isFetching 
                && !nextProps.city.isError
                && !nextProps.city.list)
            || !nextProps.city ){
            dispatch( cityActions.load( ) )
            return false
        }

        this.setState({
            cityList: nextProps.city.list
        });
    }

    componentDidMount() {
        document.title = "Héctor Martí"
        document.description = "Travel Log, the best way to plan what to visit and keep your memories"
    }
    

    toggleDrawer = (open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
            }

        const newState = update(this.state, { openDrawer: {$set: open} } )

        this.setState(newState)
    }

    handleOpenDialog = (cityId) => {
        const newState = update(this.state, { 
            openDialog: {$set: true},
            cityId: {$set: cityId}
        })
        this.setState(newState)
    }

    handleCloseDialog = () => {
        const newState = update(this.state, { openDialog: {$set: false} } )
        this.setState(newState) 
    }

	render(){
        const {cityList,openDrawer,openDialog,cityId} = this.state

		const main = <MapContainer cityList={cityList} handleOpenDialog={this.handleOpenDialog} />
		const drawer = <CityListContainer cityList={cityList} handleOpenDialog={this.handleOpenDialog}/>
                          
		return <PageDrawer main={main} 
            drawer={drawer}
            toggleDrawer={this.toggleDrawer} 
            openDrawer={openDrawer} 
            handleCloseDialog={this.handleCloseDialog}
            openDialog={openDialog}
            cityId={cityId}/>
		
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        city: state.city,
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
