import React, {Component} from 'react';

import { connect } from 'react-redux'

import update from 'immutability-helper';

import City from './city/City';

import { cityService } from '../../../services';


class CityContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            city: "",
            photoList: [],
            prevCity: {},
            nextCity: {},
            downloadOriginal: false
        }
    }
	
    handleDownloadOriginalChange = () => {
        const {downloadOriginal} = this.state
        const newState = update(this.state, { downloadOriginal: {$set: !downloadOriginal} } )

        this.setState(newState)
    }

    getCity = (iCityId) => {
        cityService.loadCity( iCityId ).then( res => {
            const cityList = this.props.city.list
            const cityId = res.rows.city.id

            let currentIndex = 0
            cityList.map( (city,index) => {
                if( city.id === cityId ){
                    currentIndex = index
                }
                return true
            })
            let nextCity = {}
            let prevCity = {}
            if( currentIndex < cityList.length){
                prevCity = {
                    id: cityList[currentIndex + 1].id,
                    name: cityList[currentIndex + 1].name,
                }
            }

            if( currentIndex > 1 ){
                nextCity = {
                    id: cityList[currentIndex - 1].id,
                    name: cityList[currentIndex - 1].name,
                }
            }

            this.setState({
                city: res.rows.city,
                photoList: res.rows.photoList,
                prevCity: prevCity,
                nextCity: nextCity
            })

        })
    }

    componentWillMount(){
        this.componentWillReceiveProps( this.props );  
    }
    
    componentWillReceiveProps( nextProps ){

        if( nextProps.city && (
                !(this.state.city.id) 
                || nextProps.cityId !== this.state.city.id ) 
            ){
            this.getCity(nextProps.cityId);
        }        
    }

    render(){
        const {city,photoList,prevCity,nextCity,downloadOriginal} = this.state   
        if(!city) return false
        return <City {...this.props} 
            getCity={this.getCity}
            city={city} photoList={photoList}
            prevCity={prevCity} nextCity={nextCity} 
            downloadOriginal={downloadOriginal}
            handleDownloadOriginalChange={this.handleDownloadOriginalChange} />        
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        city: state.city,
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CityContainer);
