let apiUrl;
let domainUrl;
let domainContent;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  apiUrl = 'http://localhost:5008/';
  domainUrl = 'http://localhost:3000/';
  domainContent = 'https://hectormarti.com/';
} else {
  apiUrl = 'https://x.hectormarti.com/hm/';
  domainUrl = 'https://hectormarti.com/';
  domainContent = 'https://hectormarti.com/';  
}


export const API_URL =	apiUrl;
export const DOMAIN_URL =	domainUrl;
export const DOMAIN_CONTENT =	domainContent;