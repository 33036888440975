import React, {Component} from 'react';

import CityList from './cityList/CityList';

class CityListContainer extends Component {

	render(){
        const {cityList,handleOpenDialog} = this.props
        if( !cityList ) return false
		return <CityList cityList={cityList} handleOpenDialog={handleOpenDialog}  />		
	}
}


export default CityListContainer;
