import {fetchApi} from '../helpers';

export const cityService = {
	load,
	loadCity
};

function load() {
    const res  = fetchApi('api/cityLoad', {
    }) 

    return res
}

function loadCity( iCityId) {
    const res  = fetchApi('api/cityLoadById', {
    	id: iCityId
    }) 

    return res
}