import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';

import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  	appBar: {
    	zIndex: theme.zIndex.drawer + 1,  
    	position: 'fixed',
    	width: '100%',
    	'-webkit-filter': 'drop-shadow(0px 0px 10px rgba(0,0,0,0.5))',    	
  	},
    stamp: {
   	width: '100%',
      background: 'radial-gradient( transparent 0px, transparent 4px, white 4px, white)',
      backgroundSize: '20px 20px',
      backgroundPosition: '-5px -5px',
      height: '5px',
    	//boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    	boxShadow: '0px -10px 16px -1px rgba(0,0,0,0.4), 0px -10px 13px 0px rgba(0,0,0,0.28), 0px -10px 18px 0px rgba(0,0,0,0.24)',
    },
   toolbar: {
    	backgroundColor: '#FFF',
    	width: '100%',
    },
  	grow: {
      fontFamily: theme.typography.handWrite.fontFamily,
		  flexGrow: 1,
    },
  	menuButton: {
    	marginLeft: 12,
    	marginRight: 36,
  	},
  	socialMenu:{
      float: "right",
      marginRight: theme.spacing(2)
    },
})

function openSocial( sUrl ){
  window.open(sUrl, "_blank")
}

const Menu = (props) => {
  	const { classes, openDrawer, toggleDrawer } = props;
  	return <div className={classes.appBar}>
   <Toolbar disableGutters={!openDrawer} className={classes.toolbar}>
      <IconButton
          aria-label="Open Menu"
          onClick={toggleDrawer(true)}
          className={classNames(classes.menuButton, {
            [classes.hide]: openDrawer,
        })}
      >
                <MenuIcon />
          </IconButton>
          <Typography className={classes.grow} variant="h6" noWrap>
            Héctor Martí
          </Typography>
         
         <div className={classes.socialMenu}>
              <IconButton onClick={() => openSocial("https://www.facebook.com/hector.marti11")} 
                color="inherit">
              <FontAwesomeIcon icon={['fab','facebook-square']} size="sm"/>
              </IconButton>
              <IconButton onClick={() => openSocial("https://www.instagram.com/hectormarti7/")}  
                color="inherit"  >
                <FontAwesomeIcon icon={['fab','instagram']} size="sm"/>
              </IconButton>
      </div>
      </Toolbar>
      <div className={classes.stamp} />
  </div>	
}

export default withStyles(styles)(Menu);