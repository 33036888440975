import { combineReducers } from 'redux'
import alert from './alert'

import city from './city'


const hectorMartiApp = combineReducers({
	alert,
	city,
})
 
export default hectorMartiApp