import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import CloseIcon from '@material-ui/icons/Close';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import ButtonGroup from '@material-ui/core/ButtonGroup';

import { withStyles } from '@material-ui/core/styles';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { DOMAIN_CONTENT} from '../../../../constants';
import Switch from '@material-ui/core/Switch';


var moment = require('moment');

const TransitionDialog = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
	dialogLgTitle:{
		textAlign: 'center',
	},
	navButtons: {
		position: 'absolute',
      left: theme.spacing(2) ,
      top: theme.spacing(2),
      color: theme.palette.grey[500],
	},
	closeButton: {
      position: 'absolute',
      right: theme.spacing(1) ,
      top: theme.spacing(1),
      color: theme.palette.grey[500],
   }
   ,
   media: {
   	height: 440,
   	position: 'relative',
  	},
  	cityTitleBox: {
  		position: 'absolute',
  		bottom: theme.spacing(2),
  		left: theme.spacing(2),
  		textAlign: 'center',
  		color: theme.palette.primary.dark,
  		textShadow: '2px 2px #000',
  		opacity: 0.95,
  	},
  	cityTitle: {
  		lineHeight: '3rem',
  		fontFamily: theme.typography.handWrite.fontFamily,
  	},
  	content: {
  		padding: 0
  	},
  	photoListBox: {
   	display: 'flex',
    	flexWrap: 'wrap',
    	justifyContent: 'space-around',
    	overflow: 'hidden',
  	},
  	gridList: {
  		padding: theme.spacing(2),
    	width: '100%',
    	//height: 450,
  	},
  	photoThumb: {
  		cursor: 'zoom-in'
  	}
});

function openPhoto( oPhoto, sSize = 'lq'){
	let sSrc = getPhotoSrc( oPhoto, sSize)
	window.open(sSrc, "_blank")
}

function getPhotoSrc( oPhoto, sSize = "tn" ){
  let photoUrl = DOMAIN_CONTENT +'assets/content/thumb/photo/'+ sSize +'/1/'+ oPhoto.city_id +'/'+ oPhoto.filename
  if( sSize === "original" ){
    photoUrl = DOMAIN_CONTENT +'assets/content/photo/1/'+ oPhoto.city_id +'/'+ oPhoto.filename
  }

	return photoUrl 
}

function City (props ){

	const { openDialog,handleCloseDialog,city,classes,photoList,
    getCity,nextCity,prevCity,
    downloadOriginal, handleDownloadOriginalChange} = props

	const matches = useMediaQuery('(min-width:600px)')

	let menuNav = []
	if( prevCity.id ){
		menuNav.push(
			<Button color="inherit" onClick={id => getCity(prevCity.id)} key={prevCity.id} aria-label={prevCity.name}>
         	<ArrowLeftIcon />{prevCity.name}
			</Button>
		)
	}
	if( nextCity.id ){
		menuNav.push(
			<Button color="inherit" onClick={id => getCity(nextCity.id)} key={nextCity.id} aria-label={nextCity.name}>
         	{nextCity.name} <ArrowRightIcon />
			</Button>
		)
	}
	
	
	let param =  {
		gridCol: 1,
		fullScreen: true,
		fullWidth: false,
		maxWidth: 'md',
		appBar: <AppBar className={classes.appBarDialog}>
       	<Toolbar>
         	<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
           		<CloseIcon />
         	</IconButton>
         	<Typography variant="h6" className={classes.titleDialog}>
           		{city.name}
         	</Typography>
         	{menuNav}
       	</Toolbar>
     	</AppBar>
	}

	if( matches ){
		param.gridCol = 4
		param.fullScreen = false
		param.fullWidth = true
		param.maxWidth = 'xl'
		param.appBar =  <DialogTitle disableTypography className={classes.dialogLgTitle}>      	
      	<ButtonGroup size="small" className={classes.navButtons}>
            {menuNav}
         </ButtonGroup>

        <Typography variant="h6">{city.name}</Typography>  
      	
        <div className={classes.closeButton}>
          <Switch checked={downloadOriginal} onChange={handleDownloadOriginalChange} value="fullWidth" />
          <IconButton aria-label="close" onClick={handleCloseDialog}>
         	  <CloseIcon />
          </IconButton>
        </div>

   	</DialogTitle>	
	}

	let journal = ""
	if( city.journal !== ""){
		journal = <CardContent>
       	<Typography variant="body2" color="textSecondary" component="p" 
       		dangerouslySetInnerHTML={{ __html: city.journal}}>
       	</Typography>          		
     	</CardContent>
	}

  let sDownloadPhotoSize =  (downloadOriginal)?'original':'lq'
	return  <Dialog open={openDialog} onClose={handleCloseDialog}
		fullScreen={param.fullScreen} fullWidth={param.fullWidth} maxWidth={param.maxWidth}
		TransitionComponent={TransitionDialog}>
     	{param.appBar}
     	<DialogContent dividers className={classes.content}>
			<CardMedia
				image={DOMAIN_CONTENT +'assets/content/city_big/1/'+ city.url +'.jpg'}
				className={classes.media} title={city.name} >
				<div className={classes.cityTitleBox}>        			
        			<Typography variant="h1" component="h2" className={classes.cityTitle}>
          			{city.name}
        			</Typography>
        			<Typography variant="h6" gutterBottom className={classes.cityTitle}>
          			{moment(city.in_date).format("dddd, D [de] MMMM, YYYY")}
        			</Typography>
        		</div>
          
      </CardMedia>
     		{journal}
     		
	     	<GridList className={classes.gridList} cols={param.gridCol}>
   	  		{photoList.map( photo => {
       			return <GridListTile key={photo.id} cols={1}
       				onClick={() => openPhoto(photo,sDownloadPhotoSize)}>
       					<img alt={city.name}
                  className={classes.photoThumb} src={getPhotoSrc( photo, 'tn' )} />
                }
       				</GridListTile>
       		})} 
     		</GridList>
      </DialogContent>	
   </Dialog>
}

export default withStyles(styles)(City);