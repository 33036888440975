import React, {Component} from 'react';

import { connect } from 'react-redux'

import Map from './map/Map';

class MapContainer extends Component {

	render(){
        const {cityList,handleOpenDialog} = this.props
        if( !cityList ) return false
		return <Map cityList={cityList} handleOpenDialog={handleOpenDialog}  />		
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        city: state.city,
    };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
