import React, {Component} from 'react';
import { connect } from 'react-redux'

import Menu from './menu/Menu'

class MenuContainer extends Component { 

	render(){
		if( !this.props.toggleDrawer ) return false
		
		return  <Menu {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  	return {}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
