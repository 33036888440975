import { createMuiTheme } from '@material-ui/core/styles';

/*
  <color name="primaryTextColor">#000000</color>
  <color name="secondaryTextColor">#ffffff</color>
*/


export const theme = createMuiTheme({
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      useNextVariants: true,
      handWrite: {
        fontFamily: 'Kalam'
      }
    },
  	palette: {
   		primary: { 
   			main: '#b2dfdb',
   			light:	'#e5ffff',
   			dark:	'#82ada9',
        text: '#000',
   		},
    	secondary: { 
    		main: '#ff5252',
    		light:	'#ff867f',
    		dark:	'#c50e29',    
        text: '#000',	
        textDark: '#FFF', 	
    	},
    	background: { 
    		default: '#FFF',
   			main: '#5efc82',
   			light:	'#5efc82',
   			dark:	'#009624',   			
   		},
  	},
});