import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';

import MainPage from '../components/page/MainPage';

import 'typeface-roboto'

import { alertActions } from '../actions';
import { history } from '../helpers/history';

import { theme } from '../themes/main';

import { library } from '@fortawesome/fontawesome-svg-core'
import { } from '@fortawesome/free-solid-svg-icons'

import { faFacebookSquare, faInstagram} from '@fortawesome/free-brands-svg-icons'

library.add( faFacebookSquare, faInstagram)


class App extends Component { 

  constructor(props) {
   	super(props);

    	const { dispatch } = this.props;
    	history.listen((location, action) => {
      	// clear alert on location change
       	dispatch(alertActions.clear());
    	});
   }

   render(){
      return <MuiThemeProvider theme={theme}>
        	<Switch>
            <Route exact path="/" component={MainPage} />
            <Route exact path="/c/:city" component={MainPage} />        
			</Switch>
		</MuiThemeProvider>
    }
}

const mapStateToProps = (state, ownProps) => {
  const { authentication } = state;
  return {
    auth: authentication,
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));  