import {
  CITY_LOAD_REQUEST,
  CITY_LOAD_SUCCESS,
  CITY_LOAD_FAILURE,  
} from '../actions/city'

import update from 'immutability-helper';

//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = true ? { loggedIn: true } : {};

const city = (state = [], action) => {
	let newState = ""

	newState = Object.assign( {}, state )		

	switch (action.type) {
		case CITY_LOAD_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case CITY_LOAD_SUCCESS:			
			if( action.rows ){				 
				newState = update( newState, {
					isFetching: {$set: false},
					isError: {$set: false},					
					list: {$set: action.rows},
				});
			}
			return newState 

		case CITY_LOAD_FAILURE:
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState;
		default:
			return state
  }
}

export default city