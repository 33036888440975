import { cityService } from '../services';
import { alertActions } from './';


export const cityActions = {
    load,
}

export const CITY_LOAD_REQUEST = 'CITY_LOAD_REQUEST'
export const CITY_LOAD_SUCCESS = 'CITY_LOAD_SUCCESS'
export const CITY_LOAD_FAILURE = 'CITY_LOAD_FAILURE'

function load() {
    return dispatch => {
        dispatch(request( ));   
        cityService.load()
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: CITY_LOAD_REQUEST } 
    }
    function success(rows) { return { type: CITY_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: CITY_LOAD_FAILURE, error } }
}