import React, {Component} from 'react';


import { Map as LeafLetMap, TileLayer, Marker, Polyline,ZoomControl } from 'react-leaflet'
import L from 'leaflet';

import { withStyles } from '@material-ui/core/styles'

import { DOMAIN_CONTENT} from '../../../../constants';

const styles = theme => ({
   mapContainer:{
      height: '100vh',
      width: '100%' 
   }
})

function getTransportAttr( sTransportation ){
   let sColor  = ""
   let iOpacity  = ""
   let iWeight  = ""
   let sRepeat  = ""
   switch( sTransportation ){
      case 'plane':
         sColor      =  "white";
         iOpacity =  1;
         iWeight     =  2;
         sRepeat     =  "25%";
         break;
      case 'ship':
         sColor      =  "blue";
         iOpacity =  0.5;
         iWeight     =  3;
         sRepeat     =  "25%";
         break;               
      case 'train':
         sColor      =  "orange";
         iOpacity =  0.5;
         iWeight     =  3;
         sRepeat     =  "30%";
         break;                  
      case 'subway':
         sColor      =  "orange";
         iOpacity =  0.6;
         iWeight     =  4;
         sRepeat     =  "40%";
         break;
      case 'bus':
         sColor      =  "black";
         iOpacity =  0.6;
         iWeight     =  4;
         sRepeat     =  "40%";
         break;
      case 'car':
         sColor      =  "black";
         iOpacity =  0.6;
         iWeight     =  4;
         sRepeat     =  "40%";
         break;
      case 'bicycle':
         sColor      =  "green";
         iOpacity =  0.7;
         iWeight     =  5;
         sRepeat     =  "50%";
         break;
      case 'walking':
         sColor      =  "green";
         iOpacity =  0.7;
         iWeight     =  5;
         sRepeat     =  "50%";
         break;                              
      default:
         sColor      =  "black";
         iOpacity =  0.5;
         iWeight     =  3;
         sRepeat     =  "25%";
         break;
   }
   return {color: sColor, opacity: iOpacity, weight: iWeight, repeat: sRepeat}
}
class Map extends Component {    	
	
	render() {
		const { cityList, classes, handleOpenDialog} = this.props
		if( !cityList ) return false


   	let cordOrig = [41.3850640,2.1734040];
   	var cordOld = []
   	var cordCity= []

   	var currentLocation = {
   		lat: 0,
   		lng: 0,
   	}

   	if( cityList.length > 0 ){
   		currentLocation = {
   			lat: cityList[0].lat,
   			lng: cityList[0].lng
   		}
   	}

      var sTileNational = { 
         url:'https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}',
         attribution: 'Tiles &copy; Esri &mdash; National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC',
      };
      /*let sTileStreetMap = {
         url :'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
         attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }
      
      var sTileWaterColor = { 
         url:'https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg',
         attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      };
      var sTileTopo = {
         url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', 
         attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
      }*/
      
      let sTile = sTileNational

		return  <div className={classes.mapContainer} >
		<LeafLetMap  style={{height: '100%',width: '100%'}}
			center={currentLocation}
			zoom={5}
         maxZoom={10}
         minZoom={2}
         zoomControl={false}
			dragging={true} >
		   
         <TileLayer
		    attribution={sTile.attribution}
		    url={sTile.url}
          maxZoom={10}
		   />

         <ZoomControl position="bottomright" />

		{cityList.map((city, index) => {
         let cityIndex = cityList.length - index
		   return <Marker
		   	key={city.id}
		      onLoad={marker => {
		         //console.log('marker: ', marker)
		         }}
		      icon={L.icon({
             iconUrl: DOMAIN_CONTENT +'assets/content/city_icon/1/'+ city.url +'.png',
             iconSize: [75, 75],
             iconAnchor: [35, 75], // Posicion del icono respeto a las cordenadas, Lateral, Arriba 
               })
            }            
		      position={{
		         lat: city.lat,
		         lng: city.lng
		      }}
            onClick={(cityId) => handleOpenDialog(city.id)}
            zIndexOffset={cityIndex}
            riseOnHover={true}
            riseOffset="1000"
		   />
		})}
		{cityList.slice().reverse().map((city, index) => { 
		   cordOld = cordOrig
		   cordCity = [city.lat,city.lng]
		   cordOrig = cordCity
		   let rPositions = []
		   rPositions.push(cordOld)
		   if(city.route){
		      let routeCord = city.route.split(",").reverse();

		      routeCord.map( rc => {
		         let c = rc.split(":")
		         if( c[0] && c[1]){
		            rPositions.push([c[0],c[1]])
		         }
		         return true
		      })
		   }
		   rPositions.push(cordCity)
		   let oAttr = getTransportAttr( city.transport )
		   return <Polyline 
		   	key={"polyline_"+ city.id}                          
		      positions={rPositions}
		      color={oAttr.color}
		      weight={oAttr.weight}
		      opacity={oAttr.opacity}
		   />
		})}

		<Marker 
		   position={currentLocation}
		   icon={L.icon({
		       iconUrl: DOMAIN_CONTENT +'/assets/content/current/current_1.png',
		       iconSize: [100, 100],
		       iconAnchor: [50, 100],             
		   })}
         zIndexOffset={cityList.length + 1}
		/>

		</LeafLetMap>
	</div>
	}
}

export default withStyles(styles)(Map);