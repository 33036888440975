import React, {Component} from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import update from 'immutability-helper';

import InfiniteScroll from 'react-infinite-scroller';
import LazyLoad from 'react-lazy-load';

import { withStyles } from '@material-ui/core/styles'

import { DOMAIN_CONTENT} from '../../../../constants';

const styles = theme => ({
	cityRow: {
		padding: 0,
	},
	cityInfo: {
    	position: "absolute",
    	bottom: 0,
    	right: 0,
    	height: "28px",
    	width: "100%",
    	background: "linear-gradient(90deg,transparent,#000)",
	},
	cityName: {
		fontFamily: theme.typography.handWrite.fontFamily,
    	color: "#fff",
    	fontSize: "20px",
    	marginRight: "10px",
    	marginLeft: "10px",
    	position: "absolute",
    	right: "5px",
    },
	transport: {
    	transition: "bottom 2s,height 1s",
    	textAlign: "center",
    	backgroundColor: '#FFF',
    	height: '100%',
	},
	stamp: {
    	zIndex: 1,		
		position: "absolute",
    	bottom: "-25px",
    	left: "5px",  
    	height: "60px",
    	width: "60px",  	
		//background: 'white',
      	background: 'radial-gradient( transparent 0px, transparent 4px, white 4px, white)',
      	backgroundSize: '12px 12px',
      	backgroundPosition: '-6px -6px',
      	padding: '8px',
      	'-webkit-filter': 'drop-shadow(0px 0px 10px rgba(0,0,0,0.5))',    	

    },
	transportIco: {
    	height: "24px",
    	marginTop: "10px",
    	textAlign: "center",
	},
	transportKm: {
		position: 'absolute',
		bottom: '6px',
		left: '0px',
		width: '100%',
		textAlign: 'center',
		fontSize: "10px",
		lineHeight: "10px",
    	fontWeight: 700
	},
	loader: {
		textAlign: 'center',
		padding: theme.spacing(3)
	}

})

class CityList extends Component {    	
	constructor(props) {
        super(props);

        this.state = {
            hasMoreItems: true,
            cityRow: [] 
        };
    }

 	cityRow = (city) => {
		const { classes,handleOpenDialog } = this.props

		const cityKm = (city.km > 0) ?<span className={classes.transportKm}>{city.km} km</span>:""
	  	return <ListItem button className={classes.cityRow} key={city.id} onClick={() => handleOpenDialog(city.id)}>
	  		<LazyLoad 
	      		width={'100%'}
	      		height={125}
	      		debounce={false}
	      		offsetVertical={500}
	      	>
				<img 
					style={{width: '100%'}}
					alt={city.name}
					src={DOMAIN_CONTENT +'assets/content/city_side/1/'+ city.url +'.jpg'} />
			</LazyLoad>
			
			<div className={classes.stamp} >
				<div className={classes.transport}>
					<img src={DOMAIN_CONTENT  +'/assets/content/transport/'+ city.transport +'.png'} 
						alt={city.transport}
						className={classes.transportIco} />
					{cityKm}
				</div>
			</div>
			<div className={classes.cityInfo} >
				<span className={classes.cityName} >{city.name}</span>
			</div>
		</ListItem>
	}

	handleLoadMore = (page) => {
		const { cityList, classes} = this.props
		if( !cityList|| !classes ) return false

		const iNum = 20
		var items = []

		cityList.slice(page*iNum,(page*iNum)+ iNum).map( city => {
			items.push( this.cityRow(city) )
			return true
		})
		let bHasMoreItems = true
		if( page > cityList.length / iNum){
			bHasMoreItems = false
		}
		const newState = update(this.state, { 
			cityRow: {$push: items},
			hasMoreItems: {$set: bHasMoreItems } } )

        this.setState(newState)

		return true
	}

	render() {
		const { cityList, classes} = this.props
		if( !cityList ) return false	


		return  <List style={{height:'100vh',overflow: 'auto'}}>
			<InfiniteScroll
		        pageStart={0}
		        loadMore={this.handleLoadMore}
		        hasMore={this.state.hasMoreItems}
		        loader={<div className={classes.loader} key={0}>Loading ...</div>}
		        useWindow={false}
		    >
	       		{this.state.cityRow}
	    	</InfiniteScroll>
      	</List>     	
	}
}

export default withStyles(styles)(CityList);